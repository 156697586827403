import * as Cookie from '@integrabeauty/cookie';
import type * as _ShopifyBrowserTypes from '@integrabeauty/shopify-browser-types';

declare global {
  /**
   * Initialized in swp-relay liquid to the value of localization.market.handle
   *
   * @example "us"
   * @example "ca"
   * @example "gb"
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var swp_market_handle: string;

  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var swp_event_queue: Event[];

  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var swp_event_listener: (event: Event)=> void;
}

const listeners: Record<string, (event: any)=> void> = {
  'attentive-lead-captured': forward,
  'bis-form-submitted': forward,
  'cart-updated': forward,
  'checkout-started': forward,
  'collection-filtered': forward,
  'consent.onetrust': onConsentUpdated,
  'gladly-conversation-ended': forward,
  'gladly-conversation-started': forward,
  'gladly-message-received': forward,
  'gladly-message-sent': forward,
  'hair-quiz-completed': forward,
  'hair-quiz-result-clicked': forward,
  'marketing-subscribed': forward,
  'promo-banner-clicked': forward,
  'promo-banner-viewed': forward,
  'product-list-viewed': forward,
  'product-variant-viewed': onProductVariantViewed,
  'search-result-clicked': forward,
  'searched': forward,
  'shoppable-video-viewed': forward,
  'visitor-authenticated': forward,
  'yotpo-question-created': forward,
  'yotpo-review-submitted': forward,
  'yotpo-reviews-read': forward
};

let didLoadFeatures = false;

/**
 * Relays a product-variant-viewed event as both a product-variant-viewed event and also a product-
 * viewed event. The event was renamed, but several live pixels are still using the old name, so we
 * have to emit both events until the pixels are migrated.
 *
 * @todo update the pixels that listen for product-viewed to instead listen for the new event, and
 * then only forward the new event
 */
function onProductVariantViewed(event: WindowEventMap['product-variant-viewed']) {
  forward(event);

  forward(new CustomEvent('product-viewed', {
    detail: event.detail
  }));
}

/**
 * Relays the given event to Shopify's Web Pixel Manager.
 *
 * We relay all events regardless of consent because consent is checked downstream by the various
 * service workers.
 *
 * For unclear reasons the publish function is routinely not defined by the time this runs. We were
 * logging this for a while, and have investigated, and there is no explanation. One plausible
 * reason is because someone is using a broken browser extension that messes with globals or an out
 * of date browser. We have decided to stop monitoring this error in Sentry because it just amounts
 * to spam. When this error happens we ignore it and just log a message.
 *
 * @todo we cannot naively relay all events to web pixels, only certain kinds of values can be
 * included in the properties of events because the events cross window boundaries into the sandbox
 * and we end up errors in the sandboxes when processing the events, Shopify's WPM library has a
 * validation check that routinely throws. We cannot use a try/catch here to suppress the errors. We
 * will need to refactor this module to explicitly relay only specific events using only specific
 * properties with full knowledge and coupling to each particular event and its impact on downstream
 * services. fixing this will fix the "value must be on the following types ..." errors.
 *
 * @see https://shopify.dev/docs/api/pixels/customer-events
 * @see https://shopify.dev/api/consent-tracking
 */
function forward(event: CustomEvent) {
  const detail: Record<string, any> = event.detail;
  if (typeof detail === 'object' && detail !== null) {
    Shopify.analytics?.publish(event.type, detail);
  } else {
    Shopify.analytics?.publish(event.type);
  }
}

function onConsentUpdated() {
  if (didLoadFeatures) {
    onFeaturesLoaded(null);
    return;
  }

  if (typeof Shopify.loadFeatures === 'function') {
    Shopify.loadFeatures(
      [
        {
          name: 'consent-tracking-api',
          version: '0.1'
        }
      ],
      onFeaturesLoaded);
  }
}

function onFeaturesLoaded(error: any) {
  if (error) {
    console.log('load error', error);
    return;
  }

  didLoadFeatures = true;

  // eslint-disable-next-line @typescript-eslint/no-deprecated
  if (typeof Shopify.customerPrivacy?.setTrackingConsent !== 'function') {
    console.log('loaded privacy features but customerPrivacy.setTrackingConsent is not a function');
    return;
  }

  const consent = hasConsent(window.swp_market_handle);
  Shopify.customerPrivacy.setTrackingConsent({
    analytics: consent,
    marketing: consent,
    sale_of_data: consent
  }, _output => {
    console.log('set tracking consent');
  });
}

function onDOMContentLoaded(_event: Event) {
  if (location.protocol !== 'https:') {
    return;
  }

  if (location.hostname !== 'langehair.com') {
    return;
  }

  for (const type in listeners) {
    removeEventListener(type, swp_event_listener);
  }

  for (const event of swp_event_queue) {
    try {
      listeners[event?.type]?.(event);
    } catch (error) {
      console.warn(error);
    }
  }

  swp_event_queue = [];

  for (const type in listeners) {
    addEventListener(type, listeners[type]);
  }
}

/**
 * Return whether the visitor consents to tracking for marketing purposes.
 *
 * This does not check the DoNotTrack property because that was deprecated.
 *
 * @param marketHandle the value corresponding to liquid's localization.market.handle
 * @returns whether the visitor consents to tracking
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
 */
function hasConsent(marketHandle: string) {
  let cookie;
  try {
    cookie = Cookie.read('OptanonConsent');
  } catch (error) {}

  const groups: Record<string, '0' | '1' | undefined> = {};
  if (cookie) {
    let params;
    try {
      params = new URLSearchParams(cookie);
    } catch (error) {}

    // The groups param contains a comma-separated list of tuples. Each tuple consists of a group
    // identifier and a true/false flag separated by a colon. For example, C0001:1,C0002:1.

    const tuples = params?.get('groups')?.split(',') || [];
    for (const tuple of tuples) {
      const [name, flag] = tuple.split(':');
      if (flag === '1' || flag === '0') {
        groups[name] = flag;
      }
    }
  }

  const ONETRUST_TARGETING_GROUP = 'C0004';
  const value = groups[ONETRUST_TARGETING_GROUP];

  // The user granted consent.
  if (value === '1') {
    return true;
  }

  // The user denied consent.
  if (value === '0') {
    return false;
  }

  // the explicit comparison here is paranoia over non-standard implementations that might use an
  // unexpected value type for this non-standard experimental property.
  if (navigator.globalPrivacyControl === true) {
    return false;
  }

  // When OneTrust returns a non-boolean we are not aware of the consent so we must choose a default
  // value. Here we fallback to considering the Shopify market. Visitors are automatically assigned
  // to a specific market based on their IP address.
  //
  // * For UK market visitors, users are opted out by default, we want to return false.
  // * For all other visitors, users are opted in by default, we want to return true.

  return marketHandle !== 'gb';
}

// Wait until the DOM has loaded to register event listeners. The reason this does not replay on
// module load is because event forwarding needs to use a Shopify WPM API exposed through the
// Shopify global and it is possible for this script to be loaded prior to Shopify's script.
// Sometimes Shopify.analytics.publish is not defined prior to the replay. It is not clear why,
// perhaps Shopify is waiting to define/expose analytics.publish until some other script is loaded.
// We need Shopify.analytics.publish to be defined in order to replay. By waiting until the DOM has
// loaded this increases the chance Shopify.analytics.publish is defined in the cases where this
// script runs too early. Waiting longer is fine because we replay events to late- registered
// listeners. The only issue is waiting too long, leading to loss of events. Also, we still are not
// guaranteeing that the publish function is defined, just increasing the chance.
//
// TODO: investigate when and how Shopify.analytics.publish is defined, record the research in docs,
// then implement a way to wait for the function to become defined.

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
